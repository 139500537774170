<template>
    <div id="roles">
      <div class="container-fluid">
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('role-list') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button v-if="canAccess(['store-role'])" variant="primary" @click="addInit" size="lg">{{ $t('add') }}</b-button>
            </template>
            <template v-slot:body>
              <b-table responsive v-if="canAccess(['get-role','update-role','delete-role'])" :items="roles.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(actions)="data">
                    <div class="actions-list d-flex align-items-center">
                      <b-button v-if="canAccess(['update-role'])" v-b-tooltip.top="$t('edit')" variant=" iq-bg-success" @click="update(data.item)"  size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                      <!-- <b-button v-if="canAccess(['delete-role'])" v-b-tooltip.top="$t('remove')" variant=" iq-bg-danger"  size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button> -->
                    </div>
                  </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                v-model="filterData.page"
                :total-rows="roles.total"
                :per-page="roles.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'roles',
  data () {
    return {
      filterData: {
        page: 1,
        take: 10,
        name: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('name'),
          key: 'name'
        },
        {
          label: this.$t('permissions-count'),
          key: 'permissions.length'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getRoles(this.filterData)
    },
    addInit () {
      this.$router.push('role-form')
    },
    update (data) {
      this.$router.push(`/role-form/${data.id}`)
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeRoles(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addRoles(this.payload).then(() => {
          this.$bvModal.hide('roles-modal')
          this.payload = this.resetObject(this.payload)
        })
      } else {
        this.payload._method = 'PUT'
        this.updateRoles(this.payload).then(() => {
          this.$bvModal.hide('roles-modal')
          this.payload = this.resetObject(this.payload)
        })
      }
    },
    changeStatus () {}
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getRoles(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getRoles()
    this.getPermissions()
  }
}
</script>
<style scoped>
  .permission{
    background-color: #e9e9e9;
    padding: 5px 10px;
    border-radius: 4px;
  }

</style>
